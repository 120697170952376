/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

import Header from './header'
import Container from '@material-ui/core/Container'
import Fade from '@material-ui/core/Fade'
import InstagramIcon from '@material-ui/icons/Instagram'
import MailOutlinedIcon from '@material-ui/icons/MailOutlined'
import './layout.css'
const Layout = ({ background, backgroundSize, children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <div
            style={{
              minHeight: '100vh',
              backgroundColor: '#000000',
              background: `${background}`,
              backgroundSize: `${backgroundSize}`,
            }}
          >
            <Header siteTitle={data.site.siteMetadata.title} />
            <Fade in={true} timeout={800}>
              <div
                style={{
                  margin: `0 auto`,
                  maxWidth: 960,
                  padding: `0px 1.0875rem 1.45rem`,
                  paddingTop: 100,
                }}
              >
                <main>{children}</main>
              </div>
            </Fade>
          </div>
          <footer
            style={{ paddingTop: 20, backgroundColor: 'black', width: '100%' }}
          >
            <Container style={{maxWidth:'960px', display: 'flex', flexDirection:'flex-row', justifyContent:'space-between', alignItems:'flex-start'}}>
              <div>
                <p>
                  © {new Date().getFullYear()} T Photography All Rights Reserved
                </p>
              </div>
              <div>
                <Link to="/contact">
                  <MailOutlinedIcon style={{ fontSize: 28, color: 'white', margin:'0 1rem 0 0' }} />
                </Link>
                <a
                  href="https://www.instagram.com/tphoto360/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon style={{ fontSize: 28, color: 'white', margin:'0 1rem 0 0' }} />{' '}
                </a>
              </div>
            </Container>
          </footer>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
