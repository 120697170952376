import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <h1>Homeless and Homebody</h1>
    <p>
      A project that started as an annoyance of people on Instagram has
      transformed into the helping hand for Minneapolis.
    </p>
    <p>
      Every day I drove by Minneapolis's version of skid-row. It's known as the
      "The Wall." It's off a major highway (55 and Ceder Ave. Exit) connecting
      downtown Minneapolis and the southern wealthier suburbs. Many turn a blind
      eye to the camp as numbers swelled. The camp had about 175 citizens but
      has dropped dramatically due to the navigation center opening. The camp
      has been mostly eradicated now. The majority of the camp consisted of a
      Native American population which suffers from addiction (heroin), mental
      health issues, and unaffordable rent costs.
    </p>
    <p>
      I was inspired by the French artist JR previous Gaza/Israel project, I
      wanted to ask the city can you tell the difference between a homebody and
      a homeless person?
    </p>
    <p>Look at portraits and see if you can pick them out.</p>
    <p>Did you make your guess?</p>
    <p>It's a trick question, they all are homeless.</p>
    <p>
      To find their names and stories, please visit the portrait gallery (not
      everyone wanted to share so some are unknown)
    </p>
    <p>
      I am NOT receiving any money nor am I affiliated with anyone for this
      project.
    </p>
    <p>
      I am hoping to help raise money for a Minneapolis Homeless Project and to
      help cover the cost of printing from the Inside Out Project and personal
      material cost.
    </p>
  </Layout>
)

export default AboutPage
